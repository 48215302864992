import React from "react"
import { graphql } from "gatsby"

import { Generic as Template } from "../components/Generic/Generic"

export const query = graphql`
  query($id: String!) {
    page: sanityPageGeneric(_id: { eq: $id }) {
      title
      slug {
        current
      }
      hide
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      content: _rawContent(resolveReferences: { maxDepth: 3 })
    }
  }
`

const Component = ({ data, ...props }) => <Template {...props} {...data} />
export default Component
