import React from "react"

import { withGeneric } from "./withGeneric"
import { RichText } from "../RichText/RichText"
import { Wrapper, Title } from "./GenericStyles"

export const Generic = withGeneric(
  ({ title, content, hideTitle }): JSX.Element => (
    <Wrapper>
      {hideTitle !== "hide" ? <Title>{title}</Title> : null}
      <RichText>{content}</RichText>
    </Wrapper>
  )
)
